import axios from 'axios';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { defineStore } from 'pinia';
import router from '@/router';
import { datadogRum } from '@datadog/browser-rum';

const baseUrl = import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:4040';
const coreUrl = import.meta.env.VITE_API_CORE_URL;
const API = axios.create({ baseURL: `${baseUrl}/api/v1` });
const API_CORE = axios.create({ baseURL: `${coreUrl}/api/v1` });
const [currentLang] = navigator.language.split('-');

API.defaults.headers.common['Accept-Language'] = currentLang || 'es';

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      expires: null,
      token: null,
      refresh: null,
      role: null,
      id: null,
      name: null,
      url: null,
      version: null,
      company: null,
      companyId: null,
      email: null,
      isCreatingAccount: false,
      stepCreatingAccount: 1,
      planSelected: null,
      countrySelected: null,
      forceChangePassword: false,
      requireFinishAccount: null,
      isLoading: false,
    };
  },
  actions: {
    async login(params) {
      const { data } = await API.post('/login', params);

      this.forceChangePassword = data.forceChangePassword;
      this.url = data.url;
      this.token = data.token;
      this.refresh = data.refresh || null;
      this.version = data.version ?? 1;
      this.decodeUser();

      if (data.redirect) {
        this.requireFinishAccount = data.redirect;

        if (data.redirect === 'payment') {
          this.setStepCreatingAccount(4);
          return false;
        }
        if (data.redirect === 'company') {
          this.setStepCreatingAccount(3);
          return false;
        }
      }
    },
    async logout() {
      try {
        await API.post(
          '/logout',
          {},
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      } catch (e) {
        datadogRum.addError(e);
      } finally {
        this.resetData();
      }
    },
    verify(token, refresh) {
      this.token = token;
      this.refresh = refresh;
      this.isCreatingAccount = true;

      return API.get('/users/verification', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    register(params) {
      return API.post('/users', params);
    },
    forgotPassword(params) {
      return API.post('/resetPassword', params);
    },
    registerTeam(id, params) {
      return API.put(`/users/${id}`, params, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
    },
    async configWorkspace(params) {
      const response = await API.post('/company', params, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      this.company = response.data;

      return response.data;
    },
    createPayment(params) {
      return API_CORE.post('/payment', params, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
    },
    configInvite(params) {
      return API.post('/users/inviteTeamMember', params, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
    },
    companiesSetup(params) {
      return API_CORE.post('/companies/setup', params, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
    },
    configBilling(payload) {
      return API_CORE.post('/zoho/customer', payload, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
    },
    setData({ token, url, version, forceChangePassword, refresh }) {
      this.url = url;
      this.token = token;
      this.version = version ?? 1;
      this.refresh = refresh;
      this.forceChangePassword = forceChangePassword;
      this.decodeUser();
    },
    resetData() {
      this.id = null;
      this.url = null;
      this.role = null;
      this.name = null;
      this.token = null;
      this.refresh = null;
      this.expires = null;
      this.version = null;
      this.company = null;
      this.companyId = null;
    },
    setEmail(email) {
      this.email = email;
    },
    decodeUser() {
      try {
        const user = jwtDecode(this.token);
        this.expires = moment.unix(user.exp);
        this.role = user.role;
        this.id = user.id;
        this.name = user.name;
        this.companyId = user.company;
      } catch (e) {
        datadogRum.addError(e);
        this.resetData();
      }
    },
    isValidToken() {
      return this.role && this.id && this.expires >= moment();
    },
    async refreshToken(refreshToken) {
      const { data } = await API.post('/refreshToken', {
        refreshToken,
      });

      this.token = data.token;
      this.decodeUser();
    },
    getBaseUrl() {
      if (this.url) {
        return this.url
          .replace('{{TOKEN}}', this.token)
          .replace('{{COMPANY}}', this.companyId);
      }

      if (this.role === 'agent') {
        return `${import.meta.env.VITE_CHAT_BASE_URL}?data=${this.token}`;
      }

      return `${import.meta.env.VITE_ADMIN_BASE_URL}?data=${this.token}`;
    },
    setStepCreatingAccount(step) {
      this.isCreatingAccount = true;
      this.stepCreatingAccount = step;
    },
    setStepAccountURL() {
      if (this.stepCreatingAccount === 1) {
        router.push({ name: 'ConfigAccount' });
      } else if (this.stepCreatingAccount <= 3) {
        router.push({ name: 'ConfigSpace' });
      } else if (this.stepCreatingAccount <= 4) {
        router.push({ name: 'ConfigPayment' });
      } else if (this.stepCreatingAccount <= 5) {
        router.push({ name: 'ConfigBilling' });
      } else if (this.stepCreatingAccount <= 6) {
        router.push({ name: 'ConfigPaid' });
      } else if (this.stepCreatingAccount <= 7) {
        router.push({ name: 'ConfigInvite' });
      }
    },
    clearStepsCreatingAccount() {
      this.isCreatingAccount = false;
      this.stepCreatingAccount = 1;
      this.planSelected = null;
      this.requireFinishAccount = null;
    },
    changePlan(plan) {
      this.planSelected = plan;
    },
    changeCountry(country) {
      this.countrySelected = country;
    },
    activateLoading(isLoadingFlag) {
      this.isLoading = isLoadingFlag;
    },
  },
  persist: {
    afterRestore: (ctx) => {
      ctx.store.$state.expires = moment(ctx.store.$state.expires);
    },
  },
});
